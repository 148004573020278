export function setForm(state, data) {
  state.form = data;
}

export function setFormValue(state, { prop, value }) {
  state.form[prop] = value;
}

export function addArrayValue(state, { prop, value }) {
  state.form[prop].push(value);
}

export function removeArrayValue(state, { prop, index }) {
  state.form[prop].splice(index, 1);
}

export function mapFormData(state, data) {
  Object.keys(state.form).forEach((prop) => {
    if (prop === "chat") {
      state.form[prop] = NaN;
    } else if (!isNaN(state.form[prop])) {
      state.form[prop] = data[prop] || 0;
    } else if (state.form[prop] instanceof Array) {
      state.form[prop] = data[prop] || [];
    } else {
      state.form[prop] = data[prop];
    }
  });
}

export function clearFormData(state) {
  state.form = {
    chat: 0,
    action: null,

    buyer_request_title: "",
    buyer_request_description: "",
    buyer_request_image: null,
    buyer_request_category_type: null,
    buyer_request_category: null,
    buyer_request_retail_type: null,
    buyer_request_thc_cbd_range: null,
    buyer_request_thc_value: null,
    buyer_request_thc_value_max: null,
    buyer_request_thc_unit: "%",
    buyer_request_cbd_value: null,
    buyer_request_cbd_value_max: null,
    buyer_request_cbd_unit: "%",
    buyer_request_packaged_qty_value: null,
    buyer_request_packaged_qty_unit: null,
    buyer_request_production_type: null,
    buyer_request_inventory_value: null,
    buyer_request_inventory_unit: "g",
    buyer_request_target_price: null,
    buyer_request_max_price: null,

    auction_product: null,
    auction_item: null,
    auction_category_type: null,
    auction_category: null,
    auction_retail_type: null,
    auction_is_new_brand: null,
    auction_brand: null,
    auction_brand_logo: null,
    auction_brand_name: "",
    auction_title: "",
    auction_description: "",
    auction_images: [],
    auction_thc_cbd_range: null,
    auction_thc_value: null,
    auction_thc_value_max: null,
    auction_thc_unit: "%",
    auction_cbd_value: null,
    auction_cbd_value_max: null,
    auction_cbd_unit: "%",
    auction_packaged_qty_value: null,
    auction_packaged_qty_unit: null,
    auction_strain_type: null,
    auction_trim_type: null,
    auction_trim_machine: null,
    auction_dry_method: null,
    auction_production_type: null,
    auction_production_date: null,
    auction_is_coa_file: null,
    auction_coa_files: [],
    auction_is_product_expire: null,
    auction_expiration_date: null,
    auction_package: "",
    auction_is_list_all: null,
    auction_inventory_value: null,
    auction_inventory_unit: null,
    auction_new_packages: [],
    auction_start_price_per_unit: 1.00,
    auction_start_price: null,
    auction_is_buy_now_price: null,
    auction_buy_now_price_per_unit: null,
    auction_buy_now_price: null,
    auction_is_reserve_price: null,
    auction_reserve_price_per_unit: null,
    auction_reserve_price: null,
    auction_is_promoted: null,
    auction_is_auto_relist: null,
    auction_is_start_now: null,
    auction_start_date: "",
    auction_days_duration: null,
    auction_payment_terms: [],
    auction_grow_media: [],
    auction_shipping_boxes: [],
    auction_shipping_boxes_value: {},
    auction_target_price: null,
    auction_min_price: null,
    auction_is_available_for_sale: null
  };
}

export function setNewAuctionFormData(state, data) {
  state.form = {
    chat: 0,
    action: data["action"] ?? "auction",

    buyer_request_title: "",
    buyer_request_description: "",
    buyer_request_image: null,
    buyer_request_category_type: null,
    buyer_request_category: null,
    buyer_request_retail_type: null,
    buyer_request_thc_cbd_range: null,
    buyer_request_thc_value: null,
    buyer_request_thc_value_max: null,
    buyer_request_thc_unit: "%",
    buyer_request_cbd_value: null,
    buyer_request_cbd_value_max: null,
    buyer_request_cbd_unit: "%",
    buyer_request_packaged_qty_value: null,
    buyer_request_packaged_qty_unit: null,
    buyer_request_production_type: null,
    buyer_request_inventory_value: null,
    buyer_request_inventory_unit: "g",
    buyer_request_target_price: null,
    buyer_request_max_price: null,

    auction_listing_draft_id: data["listing_draft_id"] ?? null,
    auction_product: data["product"] ?? null,
    auction_item: data["item"] ?? null,
    auction_category_type: data["category_type"] ?? null,
    auction_category: data["category"] ?? null,
    auction_retail_type: data['retail_type'] ?? null,
    auction_is_new_brand: data["brand"] ? false : data["is_new_brand"] ?? null,
    auction_brand: data["brand"] ?? null,
    auction_brand_logo: data["brand_logo"] ?? null,
    auction_brand_name: data["brand"] ? "" : data["brand_name"] ?? "",
    auction_title: data["title"] ?? "",
    auction_description: data["description"] ?? "",
    auction_images: data["images"] ?? [],
    auction_thc_cbd_range: data['thc_cbd_range'],
    auction_thc_value: data['thc_value'] ?? null,
    auction_thc_value_max: data['thc_value_max'] ?? null,
    auction_thc_unit: data['thc_unit'] ?? "%",
    auction_cbd_value: data['cbd_value'] ?? null,
    auction_cbd_value_max: data['cbd_value_max'] ?? null,
    auction_cbd_unit: data['cbd_unit'] ?? "%",
    auction_packaged_qty_value: data['packaged_qty_value'] ?? null,
    auction_packaged_qty_unit: data['packaged_qty_unit'] ?? null,
    auction_strain_type: data["strain_type"] ?? null,
    auction_trim_type: data["trim_type"] ?? null,
    auction_trim_machine: data["trim_machine"] ?? null,
    auction_dry_method: data["dry_method"] ?? null,
    auction_production_type: data["production_type"] ?? null,
    auction_production_date: data["production_date"] ?? null,
    auction_is_coa_file: data["is_coa_file"] ?? null,
    auction_coa_files: data["coa_files"] ?? [],
    auction_is_product_expire: data["is_product_expire"] ?? null,
    auction_expiration_date: data["expiration_date"] ?? null,
    auction_package: data["package"] ?? "",
    auction_is_list_all: data["is_list_all"] ?? null,
    auction_inventory_value: data["inventory_value"] ?? null,
    auction_inventory_unit: data["inventory_unit"] ?? null,
    auction_new_packages: data["new_packages"] ?? [],
    auction_start_price_per_unit: data['start_price_per_unit'] ?? 1.00,
    auction_start_price: data['start_price'] ?? null,
    auction_is_buy_now_price: data['is_buy_now_price'] ?? null,
    auction_buy_now_price_per_unit: data['buy_now_price_per_unit'] ?? null,
    auction_buy_now_price: data['buy_now_price'] ?? null,
    auction_is_reserve_price: data['is_reserve_price'] ?? null,
    auction_reserve_price_per_unit: data['reserve_price_per_unit'] ?? null,
    auction_reserve_price: data['reserve_price'] ?? null,
    auction_is_promoted: data["is_promoted"] ?? null,
    auction_is_auto_relist: data["is_auto_relist"] ?? null,
    auction_is_start_now: data["is_start_now"] ?? null,
    auction_start_date: data["start_date"] ?? null,
    auction_days_duration: data["days_duration"] ?? null,
    auction_payment_terms: data["payment_terms"] ?? [],
    auction_grow_media: data["grow_media"] ?? [],
    auction_shipping_boxes: data["shipping_boxes"] ?? [],
    auction_shipping_boxes_value: data["shipping_boxes_value"] ?? {},
    auction_target_price: data["target_price"] ?? null,
    auction_min_price: data["min_price"] ?? null,
    auction_is_available_for_sale: data['is_available_for_sale'] ?? null
  };
}
