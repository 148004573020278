const routes = [
  {
    path: "/",
    component: () => import("layouts/AppLayout"),
    children: [
      // Browsing Listings
      {
        path: "/",
        name: "auctions-browse",
        component: () => import("pages/AuctionsBrowse"),
        children: [
          {
            path: "/auctions/:id([0-9]+)",
            props: true,
            name: "auctions-view",
            component: () => import("pages/AuctionsView"),
          },
          {
            path: "/auctions/:id([0-9]+)/pay",
            props: true,
            name: "auctions-pay",
            component: () => import("pages/AuctionsCheckout"),
          },
          {
            path: "/logout",
            name: "logout-dialog",
            component: () => import("pages/AppLogout"),
          },
        ],
      },

      {
        path: "/auctions/:categoryType",
        name: "auctions-type-browse",
        component: () => import("pages/AuctionsBrowse"),
      },
      {
        path: "/auctions/:categoryType/:category",
        name: "auctions-category-browse",
        component: () => import("pages/AuctionsBrowse"),
      },

      // Featured Listings
      {
        path: "/featured",
        name: "featured-browse",
        component: () => import("pages/AuctionsBrowse"),
      },
      {
        path: "/featured/:categoryType",
        name: "featured-type-browse",
        component: () => import("pages/AuctionsBrowse"),
      },
      {
        path: "/featured/:categoryType/:category",
        name: "featured-category-browse",
        component: () => import("pages/AuctionsBrowse"),
      },

      // Listings Ending Soon
      {
        path: "/ending-soon",
        name: "ending-soon-browse",
        component: () => import("pages/AuctionsBrowse"),
      },
      {
        path: "/ending-soon/:categoryType",
        name: "ending-soon-type-browse",
        component: () => import("pages/AuctionsBrowse"),
      },
      {
        path: "/ending-soon/:categoryType/:category",
        name: "ending-soon-category-browse",
        component: () => import("pages/AuctionsBrowse"),
      },

      // Buy it Now Listings
      {
        path: "/buy-it-now",
        name: "buy-it-now-browse",
        component: () => import("pages/AuctionsBrowse"),
      },
      {
        path: "/buy-it-now/:categoryType",
        name: "buy-it-now-type-browse",
        component: () => import("pages/AuctionsBrowse"),
      },
      {
        path: "/buy-it-now/:categoryType/:category",
        name: "buy-it-now-category-browse",
        component: () => import("pages/AuctionsBrowse"),
      },

      // Watched Listings
      {
        path: "/watched",
        name: "watched-browse",
        component: () => import("pages/AuctionsBrowse"),
      },
      {
        path: "/watched/:categoryType",
        name: "watched-type-browse",
        component: () => import("pages/AuctionsBrowse"),
      },
      {
        path: "/watched/:categoryType/:category",
        name: "watched-category-browse",
        component: () => import("pages/AuctionsBrowse"),
      },

      //Product Listings
      {
        path: "/product-listings/:id",
        name: "product-listings",
        component: () => import("pages/AuctionsByProduct.vue"),
        props: true,
      },

      // Watching Listings
      {
        path: "/my/watchlist",
        name: "my-watchlist",
        component: () => import("pages/MyWatchlist"),
      },

      // Brand
      {
        path: "/brands",
        name: "brands-browse",
        component: () => import("pages/BrandsPage"),
      },
      {
        path: "/brands/:brandId",
        name: "brand-listings",
        component: () => import("pages/BrandStorefront"),
        props: true,
      },

      /*
      // My History
      {
        path: "/my/history",
        name: "my-history",
        component: () => import("pages/MyHistory"),
      },
      {
        path: "/my/history/sales/:id",
        name: "my-history-sale",
        component: () => import("pages/MyHistorySale"),
        props: true,
      },
      {
        path: "/my/history/sales/:id/pay",
        name: "my-history-sale-fee-pay",
        component: () => import("pages/MyHistoryFeePay"),
        props: true,
      },
      {
        path: "/my/history/sales/:id/packages",
        name: "my-history-sale-packages",
        component: () => import("pages/MyHistorySalePackages"),
        props: true,
      },
      {
        path: "/my/history/sales/:id/shipping-boxes",
        name: "my-history-sale-shipping-boxes",
        component: () => import("pages/MyHistorySaleShippingBoxes"),
        props: true,
      },
      {
        path: "/my/history/sales/:id/review",
        name: "my-history-sale-review",
        component: () => import("pages/MyHistoryReview"),
        props: true,
      },
      {
        path: "/my/history/purchases/:id",
        name: "my-history-purchase",
        component: () => import("pages/MyHistoryPurchase"),
        props: true,
      },
      {
        path: "/my/history/purchases/:id/pay",
        name: "my-history-purchase-fee-pay",
        component: () => import("pages/MyHistoryFeePay"),
        props: true,
      },
      {
        path: "/my/history/purchases/:id/review",
        name: "my-history-purchase-review",
        component: () => import("pages/MyHistoryReview"),
        props: true,
      },
      */

      // My Company
      {
        path: "/my/company",
        name: "my-company",
        component: () => import("pages/MyCompany"),
      },
      {
        path: "/my/company/licenses/:id",
        props: true,
        name: "my-company-license-edit",
        component: () => import("pages/MyCompanyLicenseEdit"),
      },
      {
        path: "/my/company/licenses/:id/payment-method",
        props: true,
        name: "my-company-license-payment-method",
        component: () => import("pages/MyCompanyLicensePaymentMethod"),
      },
      {
        path: "/my/company/users/:id",
        props: true,
        name: "my-company-users-edit",
        component: () => import("pages/MyCompanyUsersEdit"),
      },
      // My Customers
      {
        path: "/my/customers",
        name: "my-customers",
        component: () => import("pages/CustomersPage.vue"),
      },
      // Shipping Portal
      {
        path: "/my/transfers",
        name: "my-transfers",
        component: () => import("pages/MyTransfers"),
      },
      {
        path: "/my/transfer/:id",
        name: "my-transfer",
        props: true,
        component: () => import("pages/MyTransfer"),
      },
      {
        path: "/my/transfers/new",
        name: "my-transfers-new",
        component: () => import("pages/TransferPageContainer"),
      },
      {
        path: "/my/transfers/:id/edit",
        props: true,
        name: "my-transfer-edit",
        component: () => import("pages/MyTransferEdit"),
      },
      {
        path: "/transferHeader",
        props: true,
        name: "transfer-header",
        component: () => import("pages/TransferHeader"),
      },
      {
        path: "/transferFinalize",
        props: true,
        name: "transfer-finalize",
        component: () => import("pages/TransferFinalize"),
      },
      {
        path: "/transferPage",
        name: "transfer-page",
        component: () => import("pages/TransferPageContainer"),
      },
      {
        path: "/packageWholesale",
        name: "package-wholesale",
        component: () => import("pages/PackageWholesale"),
        props: true,
      },
      {
        path: "/packageTagCreate",
        name: "package-tag-create",
        component: () => import("components/NewPackageItem"),
        props: true,
      },
      {
        path: "/bulkPackagesCreate",
        name: "bulk-packages-create",
        component: () => import("pages/BulkPackagesCreate"),
        props: true,
      },
      // checkout
      {
        path: "/checkout",
        name: "checkout",
        component: () => import("pages/CheckOut"),
      },

      // My Brands
      {
        path: "/my/brands",
        name: "my-brands",
        component: () => import("pages/MyBrands"),
      },
      {
        path: "/my/brands/:id",
        props: true,
        name: "my-brands-edit",
        component: () => import("pages/MyBrandsEdit"),
      },
      {
        path: "/my/brands/new",
        name: "my-brands-new",
        component: () => import("pages/MyBrandsEdit"),
      },
      {
        path: "/my/products/:id([0-9]+)?",
        name: "my-product",
        component: () => import("pages/MyProduct"),
        props: (route) => ({
          item: route.state?.item || null,
        }),
      },
      {
        path: "/bulk-edit",
        name: "bulk-inventory-edit",
        component: () => import("pages/BulkInventoryEdit"),
        props: true,
      },
      // My Inventory
      {
        path: "/my/inventory",
        name: "my-inventory",
        component: () => import("pages/MyInventory"),
        meta: {
          supports_all_licenses: true, //"All Licenses" selection will be available on a page.
        },
      },
      {
        path: "/my/inventory/reorder",
        name: "my-inventory-reorder",
        component: () => import("pages/MyInventoryReorder.vue"),
      },
      {
        path: "/my/inventory/reorders",
        name: "my-inventory-reorders",
        component: () => import("pages/MyInventoryReorders.vue"),
      },
      {
        path: "/my/inventory/reorders/group/:id([0-9]+)",
        name: "my-inventory-reorder-group",
        props: true,
        component: () => import("pages/MyInventoryReorderGroup.vue"),
      },
      {
        path: "/my/inventory/reorders/group/:id([0-9]+)",
        name: "my-inventory-reorder-group",
        props: true,
        component: () => import("pages/MyInventoryReorderGroup.vue"),
      },
      {
        path: "/my/inventory/reorders/group/:id([0-9]+)/reject",
        name: "my-inventory-reorder-group-reject",
        props: true,
        component: () => import("pages/MyInventoryReorderGroupReject.vue"),
      },
      {
        path: "/my/inventory/reorders/group/:id([0-9]+)/accept",
        name: "my-inventory-reorder-group-accept",
        props: true,
        component: () => import("pages/MyInventoryReorderGroupAccept.vue"),
      },

      // My Listings
      {
        path: "/my/listings",
        name: "my-listings",
        component: () => import("pages/MyListings"),
        meta: {
          supports_all_licenses: true,
        },
      },
      // {
      //   path: "/my/listings/new",
      //   name: "my-listing-new",
      //   component: () => import("pages/MyListingsEdit")
      // },
      {
        path: "/my/listings/add",
        name: "my-listing-add",
        component: () => import("pages/MyListingAdd"),
      },
      {
        path: "/my/listings/:id([0-9]+)",
        props: true,
        name: "my-listing",
        component: () => import("pages/MyListing"),
      },
      {
        path: "/my/listings/draft/:id([0-9]+)",
        props: true,
        name: "my-listing-draft",
        component: () => import("pages/MyListingDraft"),
      },
      {
        path: "/my/listings/:id(D?[0-9]+)/edit",
        props: true,
        name: "my-listing-edit",
        component: () => import("pages/MyListingEdit"),
      },
      {
        path: "/my/listings/:id(D?[0-9]+)/preview",
        props: true,
        name: "my-listings-preview",
        component: () => import("pages/AuctionsView"),
      },

      {
        path: "/my/avatar",
        name: "my-avatar-edit",
        component: () => import("pages/MyAvatar"),
      },

      // My Production
      {
        path: "/my/production",
        name: "my-production",
        component: () => import("pages/MyProduction"),
      },

      // Mobile Pages
      {
        path: "/mobile/search",
        name: "mobile-search",
        component: () => import("pages/MobileSearch"),
      },
      {
        path: "/mobile/new",
        name: "mobile-add-new",
        component: () => import("pages/MobileMenuAddNew"),
      },

      //Buyer Requests
      {
        path: "/wanted",
        name: "buyer-requests",
        component: () => import("pages/BuyerRequests"),
      },
      {
        path: "/wanted/:categoryType",
        props: true,
        name: "buyer-requests-category-type",
        component: () => import("pages/BuyerRequests"),
      },
      {
        path: "/wanted/:categoryType/:category",
        props: true,
        name: "buyer-requests-category",
        component: () => import("pages/BuyerRequests"),
      },
      // {
      //   path: "/wanted/new",
      //   name: "new-buyer-request",
      //   component: () => import("pages/BuyerRequestsAdd"),
      // },
      {
        path: "/wanted/:id([0-9]+)",
        props: true,
        name: "view-buyer-request",
        component: () => import("pages/BuyerRequestsView"),
      },
      {
        path: "/wanted/:id([0-9]+)/make-offer",
        props: true,
        name: "add-buyer-request-offer",
        component: () => import("pages/BuyerRequestsOffersAdd"),
      },
      {
        path: "/wanted/:id([0-9]+)/offers",
        props: true,
        name: "buyer-request-offers",
        component: () => import("pages/BuyerRequestsOffers"),
      },
      {
        path: "/wanted/:id([0-9]+)/offers/:offerId([0-9]+)",
        props: true,
        name: "view-buyer-request-offer",
        component: () => import("pages/BuyerRequestsOffersView"),
      },
      {
        path: "/wanted/:id([0-9]+)/offers/:offerId([0-9]+)/accept",
        props: true,
        name: "accept-buyer-request-offer",
        component: () => import("pages/BuyerRequestsOffersAccept"),
      },

      //Inventory Listings
      {
        path: "inventory-listings/:id([0-9]+)",
        props: true,
        name: "view-inventory-listing",
        component: () => import("pages/InventoryListingsView.vue"),
      },
      {
        path: "inventory-listings/:id([0-9]+)/order",
        props: true,
        name: "inventory-listing-order",
        component: () => import("pages/InventoryListingsPlaceOrder.vue"),
      },
      {
        path: "inventory-listings/:id([0-9]+)/make-offer",
        props: true,
        name: "add-inventory-listing-offer",
        component: () => import("pages/InventoryListingsOffersAdd.vue"),
      },
      {
        path: "inventory-listings/:id([0-9]+)/offers",
        props: true,
        name: "inventory-listing-offers",
        component: () => import("pages/InventoryListingsOffers.vue"),
      },
      {
        path: "/inventory-listings/:id([0-9]+)/offers/:offerId([0-9]+)",
        props: true,
        name: "view-inventory-listing-offer",
        component: () => import("pages/InventoryListingsOffersView"),
      },
      {
        path: "/inventory-listings/:id([0-9]+)/offers/:offerId([0-9]+)/accept",
        props: true,
        name: "accept-inventory-listing-offer",
        component: () => import("pages/InventoryListingsOffersAccept"),
      },

      //Orders pages
      {
        path: "my/orders/incoming",
        props: true,
        name: "my-incoming-orders",
        component: () => import("pages/MyOrdersIncoming.vue"),
      },
      {
        path: "my/orders/incoming/:id([0-9]+)",
        props: true,
        name: "view-incoming-order",
        component: () => import("pages/MyOrderIncomingView.vue"),
      },
      {
        path: "my/orders/incoming/:id([0-9]+)/decline",
        props: true,
        name: "decline-incoming-order-items",
        component: () => import("pages/MyOrderIncomingDeclineItems.vue"),
      },
      {
        path: "my/orders/incoming/manual/:draftId([0-9]+)?",
        props: true,
        name: "manual-incoming-order",
        component: () => import("pages/MyOrderManualAdd.vue"),
      },
      {
        path: "my/orders/outgoing",
        props: true,
        name: "my-outgoing-orders",
        component: () => import("pages/MyOrdersOutgoing.vue"),
      },
      {
        path: "my/orders/outgoing/:id([0-9]+)",
        props: true,
        name: "view-outgoing-order",
        component: () => import("pages/MyOrderOutgoingView.vue"),
      },
      {
        path: "my/orders/external",
        props: true,
        name: "my-external-orders",
        component: () => import("pages/MyOrdersExternal.vue"),
      },
      {
        path: "my/orders/offers",
        props: true,
        name: "my-pending-offers",
        component: () => import("pages/MyOrdersPendingOffers.vue"),
      },

      // //My Offers Page
      // {
      //   path: "/offers",
      //   props: true,
      //   name: "my-offers",
      //   component: () => import("pages/MyOffers.vue"),
      // },
      // New Stuff
      // System Pages
      //Data Reports
      {
        path: "/data-Analytics",
        props: true,
        name: "data-analytics",
        component: () => import("pages/DataReports.vue"),
      },

      {
        path: "/leafLinkImport",
        props: true,
        name: "leafLinkImport",
        component: () => import("pages/LeafLinkImport.vue"),
      },
    ],
  },

  {
    path: "/auth",
    component: () => import("layouts/AuthLayout"),
    meta: {
      auth_page: true,
    },
    children: [
      {
        path: "",
        name: "auth",
        component: () => import("pages/Auth"),
        meta: {
          auth_page: true,
        },
      },
      {
        path: "login",
        name: "auth-login",
        component: () => import("pages/AuthLogin"),
        meta: {
          auth_page: true,
        },
      },
      {
        path: "cts-login",
        name: "AuthCTSLogin",
        component: () => import("pages/AuthCtsPortalLogin"),
        meta: {
          auth_page: true,
        },
      },
      {
        path: "apply",
        name: "auth-apply",
        component: () => import("pages/AuthApplication"),
        meta: {
          auth_page: true,
        },
      },
      {
        path: "apply/:token",
        props: true,
        name: "auth-apply-confirm",
        component: () => import("pages/AuthApplication"),
        meta: {
          auth_page: true,
        },
      },
      {
        path: "password/forgot",
        name: "auth-forgot-password",
        component: () => import("pages/AuthForgotPassword"),
        meta: {
          auth_page: true,
        },
      },
      {
        path: "password/reset/:token",
        props: true,
        name: "auth-reset-password",
        component: () => import("pages/AuthResetPassword"),
        meta: {
          auth_page: true,
        },
      },
    ],
  },

  //Publicly accessible invoice page
  {
    path: "/invoices/:code",
    name: "public-invoice",
    component: () => import("pages/PublicInvoice"),
    props: true,
    meta: {
      public_page: true,
    },
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    redirect: "/",
  },
];

export default routes;
