import { $API } from "boot/api";

export function syncTaxonomies({ dispatch }) {
  //Sync category images taxonomy.
  // dispatch("syncCategoryImages"); //TODO uncomment when this route is fixed.
  //Sync category type taxonomy.
  dispatch("syncCategoryTypes");
  //Sync category taxonomy.
  dispatch("syncCategories");
  //Sync trim type taxonomy.
  dispatch("syncTrimTypes");
  //Sync strain type taxonomy.
  dispatch("syncStrainTypes");
  //Sync production type taxonomy.
  dispatch("syncProductionTypes");
  //Sync company brands.
  dispatch("syncBrands");
  //Sync payment terms.
  dispatch("syncPaymentTerms");
  //Sync grow media.
  dispatch("syncGrowMedia");
  //sync filter cateogries.
  dispatch("syncFilterCategories");
}

export function syncCategoryImages({ commit, dispatch }, retryCount = 0) {
  const maxRetries = 3;
  const retryDelay = 300;

  $API.PUBLIC_IMAGES.GET()
    .then(({ data }) => {
      commit("setCategoryImages", data.category_images);
    })
    .catch((response) => {
      console.log("ERR", response);
      if (retryCount < maxRetries) {
        setTimeout(() => {
          dispatch("syncCategoryImages", retryCount + 1);
        }, retryDelay);
      } else {
        console.log("Max retries reached, giving up.");
      }
    });
}

export function syncCategoryTypes({ commit, dispatch }) {
  $API.CATEGORY_TYPES.GET()
    .then(({ data }) => {
      commit("setCategoryTypes", data.category_types);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncCategoryTypes");
      }, 200);
    });
}

export function syncCategories({ commit, dispatch }, { items = null } = {}) {
  $API.CATEGORIES.GET()
    .then(({ data }) => {
      commit("setCategories", data.categories);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncCategories", { items });
      }, 200);
    });
}

export function syncFilterCategories(
  { commit, dispatch },
  { items = null } = {}
) {
  $API.CATEGORIES.GET()
    .then(({ data }) => {
      if (Array.isArray(items) && items.length > 0) {
        const uniqueCategoryTypes = items.reduce((acc, item) => {
          if (
            item.category_type &&
            !acc.find((ct) => ct.id === item.category_type.id)
          ) {
            acc.push(item.category_type);
          }
          if (item.product && item.product.category) {
            acc.push(item.product.category);
          }
          return acc;
        }, []);

        const filteredCategories = data.categories.filter((category) =>
          uniqueCategoryTypes.some((ct) => ct.id === category.id)
        );

        commit("setFilterCategories", filteredCategories);
      } else {
        commit("setFilterCategories", data.categories);
      }
    })
    .catch((error) => {
      console.error("Failed to sync filter categories:", error);
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncFilterCategories", { items });
      }, 200);
    });
}

export function syncTrimTypes({ commit, dispatch }) {
  $API.TRIM_TYPES.GET()
    .then(({ data }) => {
      commit("setTrimTypes", data.trim_types);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncTrimTypes");
      }, 200);
    });
}

export function syncStrainTypes({ commit, dispatch }) {
  $API.STRAIN_TYPES.GET()
    .then(({ data }) => {
      commit("setStrainTypes", data.strain_types);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncStrainTypes");
      }, 200);
    });
}

export function syncProductionTypes({ commit, dispatch }) {
  $API.PRODUCTION_TYPES.GET()
    .then(({ data }) => {
      commit("setProductionTypes", data.production_types);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncProductionTypes");
      }, 200);
    });
}

export function syncBrands({ commit, dispatch }) {
  $API.BRANDS.GET({
    my: true, //load only current company brands
  })
    .then(({ data }) => {
      commit("setBrands", data.brands);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncBrands");
      }, 200);
    });
}

export function syncLicenses({ commit, dispatch, rootState }) {
  $API.CURRENT_COMPANY_LICENSES.GET()
    .then(({ data }) => {
      commit("setLicenses", data.licenses);
      //get current license from a "current" module.
      let currentLicense = rootState.current.license;
      if (!currentLicense) {
        //set first license as a current license.
        commit("current/setLicense", data.licenses[0], { root: true });
      } else {
        //find current license in all licenses list.
        let currentLicenseFromList = data.licenses.find(
          (l) => currentLicense.id === l.id
        );
        //check that current license is available.
        if (!currentLicenseFromList) {
          //clear listing chat.
          commit("listingV2/clearFormData", null, { root: true });
          //set first license as a current license.
          commit("current/setLicense", data.licenses[0], { root: true });
        } else {
          //update current license in state so that we always have an updated object.
          commit("current/setLicense", currentLicenseFromList, { root: true });
        }
      }
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncLicenses");
      }, 200);
    });
}

export function syncCompanyContacts({ commit, dispatch }) {
  $API.CURRENT_COMPANY_CONTACTS.GET()
    .then(({ data }) => {
      commit("setCompanyContacts", data.contacts);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncCompanyContacts");
      }, 200);
    });
}

export function syncOrderStatues({ commit, dispatch }) {
  $API.ORDERS.GET(`statuses`)
    .then(({ data }) => {
      commit("setOrderStatuses", data.order_statuses);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncOrderStatues");
      }, 200);
    });
}

export function syncOrderTypes({ commit, dispatch }) {
  $API.ORDERS.GET(`types`)
    .then(({ data }) => {
      commit("setOrderTypes", data.order_types);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncOrderStatues");
      }, 200);
    });
}

export function syncOrderSources({ commit, dispatch }) {
  $API.ORDERS.GET(`sources`)
    .then(({ data }) => {
      commit("setOrderSources", data.order_sources);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncOrderSources");
      }, 200);
    });
}

export function syncPaymentTerms({ commit, dispatch }) {
  $API.PAYMENT_TERMS.GET()
    .then(({ data }) => {
      commit("setPaymentTerms", data.payment_terms);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncPaymentTerms");
      }, 200);
    });
}

export function syncGrowMedia({ commit, dispatch }, retryCount = 0) {
  $API.GROW_MEDIA.GET()
    .then(({ data }) => {
      commit("setGrowMedia", data.grow_media);
    })
    .catch((error) => {
      if (retryCount < 5) {
        let timeout = Math.pow(2, retryCount) * 1000;
        setTimeout(() => {
          dispatch("syncGrowMedia", retryCount + 1);
        }, timeout);
      } else {
        console.error(
          "Failed to sync grow media after several attempts:",
          error
        );
      }
    });
}

export function addTempShippingBox({ commit, state }, box) {
  if (box && Object.keys(box).length > 0) {
    let currentKey = state.company.tempBoxes.length;
    box.id = `temp-${currentKey}`;
    commit("addTempBox", box);
    return box;
  }
}

export function editTempShippingBox({ commit, state }, box) {
  if (box && Object.keys(box).length > 0 && box.id) {
    commit("editTempBox", box);
    return box;
  }
}

export function updateInventoryCategoryTypesOG({ commit }, items) {
  return new Promise((resolve, reject) => {
    const uniqueCategoryTypes = items.reduce((acc, item) => {
      if (
        item.category_type &&
        !acc.find((ct) => ct.id === item.category_type.id)
      ) {
        acc.push(item.category_type);
      }

      if (
        item.product &&
        item.product.category_type &&
        !acc.find((ct) => ct.id === item.product.category_type.id)
      ) {
        acc.push(item.product.category_type);
      }

      return acc;
    }, []);

    commit("setInventoryCategoryTypes", uniqueCategoryTypes);
    resolve(uniqueCategoryTypes);
  });
}

export function updateInventoryCategoryTypes({ commit }, items) {
  return new Promise((resolve, reject) => {
    if (!Array.isArray(items)) {
      console.error("Expected an array, but received:", items);
      reject("Data format error: Expected an array");
      return;
    }

    // Process only if items is an array
    const uniqueCategoryTypes = items.reduce((acc, item) => {
      if (
        item.category_type &&
        !acc.find((ct) => ct.id === item.category_type.id)
      ) {
        acc.push(item.category_type);
      }

      if (
        item.product &&
        item.product.category_type &&
        !acc.find((ct) => ct.id === item.product.category_type.id)
      ) {
        acc.push(item.product.category_type);
      }

      return acc;
    }, []);

    commit("setInventoryCategoryTypes", uniqueCategoryTypes);
    resolve(uniqueCategoryTypes);
  });
}

export function fetchAllData({ commit, dispatch }) {
  commit("setLoading", true);

  return Promise.all([
    $API.AUCTIONS.GET(),
    $API.INVENTORY_LISTINGS.GET(),
    $API.PRODUCTS.GET(),
  ])
    .then(([auctionsResponse, listingsResponse, productsResponse]) => {
      const brands = [];
      const auctionsByBrand = {};
      const listingsByBrand = {};

      auctionsResponse.data.auctions.forEach((auction) => {
        if (auction.brand) {
          if (!auctionsByBrand[auction.brand.id]) {
            auctionsByBrand[auction.brand.id] = [];
          }
          auctionsByBrand[auction.brand.id].push(auction);
          if (!brands.some((b) => b.id === auction.brand.id)) {
            brands.push(auction.brand);
          }
        }
      });

      listingsResponse.data.inventory_listings.forEach((listing) => {
        if (listing.brand) {
          if (!listingsByBrand[listing.brand.id]) {
            listingsByBrand[listing.brand.id] = [];
          }
          listingsByBrand[listing.brand.id].push(listing);
          if (!brands.some((b) => b.id === listing.brand.id)) {
            brands.push(listing.brand);
          }
        }
      });

      const productsByBrand = {};
      productsResponse.data.products.forEach((product) => {
        if (product.brand) {
          if (!productsByBrand[product.brand.id]) {
            productsByBrand[product.brand.id] = [];
          }
          productsByBrand[product.brand.id].push(product);
          if (!brands.some((b) => b.id === product.brand.id)) {
            brands.push(product.brand);
          }
        }
      });

      commit("setAllBrands", brands);

      Object.entries(productsByBrand).forEach(([brandId, products]) => {
        commit("setProductsForBrand", {
          brandId: parseInt(brandId),
          products,
        });
      });

      Object.entries(auctionsByBrand).forEach(([brandId, auctions]) => {
        commit("setAuctionsForBrand", {
          brandId: parseInt(brandId),
          auctions,
        });
      });

      Object.entries(listingsByBrand).forEach(([brandId, listings]) => {
        commit("setListingsForBrand", {
          brandId: parseInt(brandId),
          listings,
        });
      });
    })
    .catch((error) => {
      console.error("Failed to fetch data:", error);
    })
    .finally(() => {
      commit("setLoading", false);
      dispatch("fetchAndSetUserBrands");
    });
}

export function updateProductBrand(
  { dispatch },
  { productId, brandId, productData }
) {
  return $API.PRODUCTS.POST(`${productId}`, productData)
    .then(() => {
      return dispatch("fetchAllData");
    })
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.error("Failed to update product brand:", error);
      throw error;
    });
}

export function fetchAndSetUserBrands({ commit }) {
  $API.BRANDS.GET({ my: true })
    .then((response) => {
      const userBrandIds = response.data.brands.map((brand) => brand.id);
      commit("setUserBrands", userBrandIds);
    })
    .catch((error) => {
      console.error("Error fetching user brands:", error);
    });
}

export function fetchBrandDetails({ commit, state }, brandId) {
  let newData = {
    products: [],
    listings: [],
    auctions: [],
  };

  const promises = [];

  promises.push(
    $API.PRODUCTS.GET().then(async (response) => {
      const brandProducts = response.data.products.filter(
        (product) => product.brand && product.brand.id === brandId
      );
      newData.products = brandProducts;

      const productIds = brandProducts.map((p) => p.id);

      const listingsResponse = await $API.INVENTORY_LISTINGS.GET();
      const brandListings = listingsResponse.data.inventory_listings.filter(
        (listing) =>
          listing.brand_id === brandId ||
          productIds.includes(listing.product_id)
      );
      newData.listings = brandListings;
    })
  );

  promises.push(
    $API.AUCTIONS.GET({ brand: brandId }).then((response) => {
      newData.auctions = response.data.auctions || [];
    })
  );

  return Promise.all(promises).then(() => {
    const existingProducts = state.productsByBrand[brandId] || [];
    const existingListings = state.listingsByBrand[brandId] || [];
    const existingAuctions = state.auctionsByBrand[brandId] || [];

    commit("setProductsForBrand", {
      brandId,
      products: newData.products.length
        ? newData.products
        : state.productsByBrand[brandId] || existingProducts,
    });

    commit("setListingsForBrand", {
      brandId,
      listings: newData.listings.length ? newData.listings : existingListings,
    });

    commit("setAuctionsForBrand", {
      brandId,
      auctions: newData.auctions.length ? newData.auctions : existingAuctions,
    });
  });
}

export function fetchAvailablePackageTags({ commit, rootState }, licenseId = null) {
  if (!licenseId) {
    licenseId = rootState.current.license?.id;
  }
  if (!licenseId) return Promise.reject('No license selected');

  return $API.AVAILABLE_PACKAGE_TAGS.GET(null, { license: licenseId })
    .then(({ data }) => {
      commit('setAvailablePackageTags', {
        licenseId,
        tags: data.package_tags
      });
      return data.package_tags;
    });
}
